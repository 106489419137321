<template>
  <div  v-if="card.info" class="card-header" v-loading="loading">
    <el-row type="flex" class="card-header-element">
      <div class="card-header-element-icon"><i class="fas fa-id-card-alt"/></div>
      <div class="card-header-element-text">
        <div class="card-header-element-text-header el-tag--plain el-tag--info">Сотрудник</div>
        <div class="card-header-element-text-body">
          <click-to-edit
            v-model="card.info.last_name"
            :disabled="!$canAndRulesPass(card, $permissions.CARD.UPDATE_INFO)"
            style="display: inline-block; margin-right: 5px"
            placeholder=""
            @input="infoFieldChanged('last_name')"
          ></click-to-edit>
          <click-to-edit
            v-model="card.info.first_name"
            :disabled="!$canAndRulesPass(card, $permissions.CARD.UPDATE_INFO)"
            style="display: inline-block; margin-right: 5px"
            placeholder=""
            @input="infoFieldChanged('first_name')"
          ></click-to-edit>
          <click-to-edit
            v-model="card.info.middle_name"
            :disabled="!$canAndRulesPass(card, $permissions.CARD.UPDATE_INFO)"
            style="display: inline-block"
            placeholder=""
            @input="infoFieldChanged('middle_name')"
          ></click-to-edit>
        </div>
      </div>
    </el-row>
    <el-row type="flex" class="card-header-element">
      <div class="card-header-element-icon"><i class="fas fa-briefcase"/></div>
      <div class="card-header-element-text">
        <div class="card-header-element-text-header el-tag--plain el-tag--info">Должность</div>
        <div class="card-header-element-text-body">
          <click-to-edit
            v-model="card.info.position"
            :disabled="!$canAndRulesPass(card, $permissions.CARD.UPDATE_INFO)"
            placeholder=""
            @input="infoFieldChanged('position')"
          ></click-to-edit>
        </div>
      </div>
    </el-row>
    <el-row type="flex" class="card-header-element">
      <div class="card-header-element-icon"><i class="fas fa-building"/></div>
      <div class="card-header-element-text">
        <div class="card-header-element-text-header el-tag--plain el-tag--info">Подразделение</div>
        <div class="card-header-element-text-body">
          <click-to-edit
            v-model="card.info.company_division"
            :disabled="!$canAndRulesPass(card, $permissions.CARD.UPDATE_INFO)"
            placeholder=""
            @input="infoFieldChanged('company_division')"
          ></click-to-edit>
        </div>
      </div>
    </el-row>
    <el-row type="flex" class="card-header-element">
      <div class="card-header-element-icon"><i class="far fa-building"/></div>
      <div class="card-header-element-text">
        <div class="card-header-element-text-header el-tag--plain el-tag--info">Предприятие</div>
        <div class="card-header-element-text-body">
          <click-to-edit
            v-model="card.info.company"
            :disabled="!$canAndRulesPass(card, $permissions.CARD.UPDATE_INFO)"
            placeholder=""
            @input="infoFieldChanged('company')"
          ></click-to-edit>
        </div>
      </div>
    </el-row>
    <el-row type="flex" class="card-header-element">
      <div class="card-header-element-icon"><i class="fas fa-calendar-alt"></i></div>
      <div class="card-header-element-text">
        <div class="card-header-element-text-header el-tag--plain el-tag--info">Год</div>
        <div class="card-header-element-text-body">
          {{ card.year }}
        </div>
      </div>
    </el-row>
    <el-row type="flex" class="card-header-element">
      <div class="card-header-element-icon"><i class="fas fa-clock"></i></div>
      <div class="card-header-element-text">
        <div class="card-header-element-text-header el-tag--plain el-tag--info">Период действия</div>
        <div class="card-header-element-text-body">
          <click-to-edit
            v-model="card.info.date_start"
            type="date-picker"
            :disabled="!$canAndRulesPass(card, $permissions.CARD.UPDATE_INFO)"
            style="display: inline-block; margin-right: 5px"
            placeholder=""
            @input="infoFieldChanged('date_start')"
          ></click-to-edit>
          -
          <click-to-edit
            v-model="card.info.date_end"
            type="date-picker"
            :disabled="!$canAndRulesPass(card, $permissions.CARD.UPDATE_INFO)"
            style="display: inline-block; margin-left: 5px"
            placeholder=""
            @input="infoFieldChanged('date_end')"
          ></click-to-edit>
        </div>
      </div>
    </el-row>



  </div>

</template>

<script>
import {mapGetters} from "vuex";
import ClickToEdit from "@/components/ClickToEdit.vue";

export default {
  name: "card-header",
  components: {ClickToEdit},

  props: {},

  watch: {},
  computed: {
    ...mapGetters('card', {
      card: 'card',
      loading: 'loading',
    }),
  },
  data() {
    return {

    }
  },
  mounted() {
  },
  methods: {

    infoFieldChanged(field){
      this.$api.card.updateCardInfoField({
        card_id: this.card.id,
        name: field,
        value: this.card.info[field],
      })
        .then(data => {
          this.$notify.success({
            title: 'Сохранено',
            message: 'Карта успешно сохранена'
          });
        })
        .finally(() => {

        })
    }
  }
}
</script>


<style lang="scss">

.card-header {
  display: flex;
  gap: 3rem;
  justify-content: space-between;

  &-element {

    &-icon {
      margin-right:6px;

      i {
        padding: 12px;
        background-color: #F5F7FA;
        color: #8F9298;
        border-radius: 0;
      }
    }
  }
}

</style>